import React from 'react'
import { graphql } from 'gatsby'
import MassiveTitle from '../MassiveTitle'

import { ReactComponent as Arrow } from '../../gfx/arrow-right.svg'

import './style.scss'

const JobsList = ({ data }) => {
  return (
    <section className="position-relative my-2 my-md-5">
      <MassiveTitle modifiers={['right', 'top']}>Carreers</MassiveTitle>
      <div className="container position-relative">
        <div className="mb-4">
          <h6>Jobs</h6>
          <h2>Start at our company</h2>
        </div>
        <div className="px-lg-5">
          <div className="px-2 px-lg-4 py-3 bg-light">
            {data.edges.map((job, index) => {
              const {
                node: {
                  fields: { slug },
                  id,
                  frontmatter: { title, job_type },
                },
              } = job

              return (
                <a
                  href={slug}
                  className={`c-job-listing  py-3 d-block ${index != 0 &&
                    'border-top'}`}
                  key={id}
                >
                  <h4 className="c-job-listing__type text-mid text-uppercase">
                    {job_type}
                  </h4>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="c-job-listing__title m-0 text-dark">
                      {title}
                    </h3>

                    <div className="c-job-listing__button text-secondary text-uppercase d-flex align-items-end">
                      <span>Apply</span>
                      <Arrow className="ml-2 " />
                    </div>
                  </div>
                </a>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default JobsList

export const JobsQuery = graphql`
  fragment jobs on Query {
    jobs: allMarkdownRemark(filter: { frontmatter: { type: { eq: "job" } } }) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            job_type
            apply_url
          }
        }
      }
    }
  }
`
