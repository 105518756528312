import React from 'react'

import Img from 'gatsby-image'

import CTABlock from '../components/CTABlock'
import HeroSingle from '../components/HeroSingle'
import ContactCTA from '../components/ContactCTA'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import './style.scss'
import JobsList from '../components/JobsList'
import SEO from '../components/SEO/index'
import CTABanner from '../components/CTABanner'

class Index extends React.Component {
  render() {
    const { data, location } = this.props
    const {
      page: {
        frontmatter: { gridPics, values },
      },
    } = data
    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="position-relative ">
          <HeroSingle data={data.page} />
          <CTABlock data={data.page.frontmatter.cta_block} />
        </div>

        <JobsList data={data.jobs} />
        <div className="bg-light c-footer">
          <CTABanner data={data.page.frontmatter.cta_banner} />
        </div>
      </Layout>
    )
  }
}
//        <Values data={values} />
export default Index

export const jobsQuery = graphql`
  query jobsQuery {
    page: markdownRemark(fields: { slug: { eq: "/jobs/" } }) {
      html
      id
      ...SEO
      ...ctaBlock
      ...ctaBanner
      frontmatter {
        intro_title
        title
        cover_image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1440, maxHeight: 500) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      ...values
    }
    ...jobs
  }
`

/*

        <div className="container my-md-5">
          <div className="row mb-3">
            <div className="col-8">
              <div>
                <Img fluid={gridPics[0].childImageSharp.landscape} />
              </div>
            </div>
            <div className="col-3 d-flex align-items-end">
              <div className="w-100">
                <Img fluid={gridPics[1].childImageSharp.square} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 offset-1 d-flex align-items-start">
              <div className="w-100">
                <Img fluid={gridPics[2].childImageSharp.square} />
              </div>
            </div>
            <div className="col-8">
              <div>
                <Img fluid={gridPics[3].childImageSharp.landscape} />
              </div>
            </div>
          </div>
        </div>

        gridPics {
          childImageSharp {
            landscape: fluid(quality: 90, maxWidth: 717, maxHeight: 464) {
              ...GatsbyImageSharpFluid_withWebp
            }
            square: fluid(quality: 90, maxWidth: 284, maxHeight: 300) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
 */
